import React, { useEffect, useState } from 'react'
import { readCircleApi, readPublicCircleIds } from '../api'
import CircleItem from './CircleItem'
import { useTranslation } from 'react-i18next'
import Loader from './Loader'

const primaryCircleId = 'qecaXv'

const SharedCircles = ({ isMobile = false, circleIds = [] }) => {
    const [publicCircles, setPublicCircle] = useState({})
    const [publicCirclesIds, setPublicCircleIds] = useState([])

    const { t } = useTranslation()

    useEffect(() => {
        handleLoadPublicCirclesIds()
    }, [])

    useEffect(() => {
        handleLoadPublicCircles()
    }, [publicCirclesIds.length])

    const handleLoadPublicCircles = () => {
        try {
            Object.values(publicCirclesIds).map((id) => {
                readCircleApi(id, (data) => {
                    setPublicCircle((prevState) => ({
                        ...prevState,
                        [id]: data,
                    }))
                })
            })
        } catch (e) {
            console.log(e)
        }
    }

    const handleLoadPublicCirclesIds = () => {
        try {
            if (circleIds.length > 0) {
                setPublicCircleIds(circleIds)
                return
            }
            readPublicCircleIds((data) => setPublicCircleIds(data))
        } catch (e) {
            console.log(e)
        }
    }

    if (!Object.entries(publicCircles)?.length) {
        return <Loader />
    }

    return (
        <>
            {Object.entries(publicCircles)
                // .sort(
                //     ([, a], [, b]) => a.id === 'bin-ihlas' || a.arabicText.length - b.arabicText.length,
                // )
                .sort(([, a], [, b]) => {
                    if (a.id === primaryCircleId && b.id !== primaryCircleId) {
                        return -1 // "a" comes first if "a.id" is primaryCircleId and "b.id" is not
                    }
                    if (a.id !== primaryCircleId && b.id === primaryCircleId) {
                        return 1 // "b" comes first if "b.id" is "primary" and "a.id" is not
                    }

                    if (a.arabicText.length === b.arabicText.length) {
                        return 0 // same arabicText length, maintain current order
                    }

                    return a.arabicText.length - b.arabicText.length // sort by text length
                })
                .map(([id, item]) => {
                    return <CircleItem key={id} item={item} />
                })}
            {!isMobile && (
                <p className="text-gray-700 dark:text-gray-200">
                    {t('info.firstPart')}{' '}
                    <a
                        href="mailto:developer@hamertech.io"
                        className="underline"
                        data-splitbee-event="Email Link | Shared Circles"
                        data-splitbee-event-destination="developer@hamertech.io"
                    >
                        developer@hamertech.io
                    </a>{' '}
                    {t('info.secondPart')}
                </p>
            )}
        </>
    )
}

export default SharedCircles
