import splitbee from '@splitbee/web'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'

import { useEffect, useState } from 'react'
import CustomCircles from './components/CustomCircles'
import AppHeader from './components/Header'
import SharedCircles from './components/SharedCircles'
import { TopBanner } from './components/TopBanner'
import AboutUs from './pages/AboutUs'
import Admin from './pages/Admin'
import CircleDetail from './pages/CircleDetail'
import CreateCircle from './pages/CreateCircle'
import Custom from './pages/Custom'
import Donations from './pages/Donations'
import Home from './pages/Home'
import { appLocalStorage, LocalStorageKeys } from './service/localStorage'
import { Helmet } from 'react-helmet'

splitbee.init()

function App() {
    const [mode, setMode] = useState(
        appLocalStorage.get(LocalStorageKeys.colorMode)
    )
    const [isMobileApp, setIsMobileApp] = useState(false)
    const [showTopBanner, setShowTopBanner] = useState(false)

    useEffect(() => {
        const { pathname, search } = window.location
        const params = new URLSearchParams(search)

        if (pathname.includes('mobile-app')) {
            setIsMobileApp(true)
        } else {
            setShowTopBanner(true)
        }

        const _theme = params.get('theme')
        if (_theme && ['dark', 'light'].includes(_theme)) {
            onColorSchemeChange(_theme)
        }
    }, [])

    function onColorSchemeChange(_mode) {
        setMode(_mode)
        appLocalStorage.set(LocalStorageKeys.colorMode, _mode)
    }

    return (
        <div className={mode}>
            <Helmet>
                <meta
                    property="og:image"
                    data-react-helmet="true"
                    content={'/assets/img/og-image.jpg'}
                />
                <meta
                    property="og:image:secure_url"
                    data-react-helmet="true"
                    content={'/assets/img/og-image.jpg'}
                />
                <meta
                    name="twitter:image"
                    data-react-helmet="true"
                    content={'/assets/img/og-image.jpg'}
                />
            </Helmet>

            {showTopBanner && <TopBanner />}
            <div className={'min-h-screen bg-white dark:bg-neutral-900'}>
                <div className="md:p-5 p-4 max-w-2xl mx-auto">
                    <BrowserRouter>
                        {showTopBanner && (
                            <AppHeader
                                onColorSchemeChange={onColorSchemeChange}
                            />
                        )}

                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route
                                path="/create-circle"
                                element={<CreateCircle />}
                            />
                            <Route path="/about" element={<AboutUs />} />
                            <Route path="/donations" element={<Donations />} />
                            <Route path="/qiyam" element={<Custom />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route
                                path="/mobile-app"
                                element={<SharedCircles isMobile />}
                            />
                            <Route path="/:id" element={<CircleDetail />} />
                            <Route
                                path="/mobile-app/:id"
                                element={<CircleDetail />}
                            />
                            <Route
                                path="/mobile-app/create-circle"
                                element={<CreateCircle isMobile />}
                            />
                            <Route
                                path="/mobile-app/custom-circles"
                                element={<CustomCircles isMobile />}
                            />
                        </Routes>
                    </BrowserRouter>
                </div>
            </div>
        </div>
    )
}

export default App
