export const generateID = (length) => {
    let result = ''
    const characters =
        'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789'
    const charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }
    return result
}

export const SplitbeeEvents = {
    headerShareIconClick: 'Header | Share Icon Click',
    themeChange: 'Sidebar | Theme Change',
    changeLanguage: 'Sidebar | Language Change',
    homeTabChange: 'Home | Tab Change',
    circleDetailTabChange: 'CircleDetail | Tab Change',
    circleCreate: 'Create | Circle Create',
    counterHeight: 'CircleDetail | Counter Height',
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie(cname) {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export function formatNumber(num, lan) {
    const delimiter = lan === 'en' ? ',' : '.'
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

export const publicCircleIds = [
    'h4rGf6',
    'R1SSiN',
    'bf4XuM',
    'RMFarV',
    'cL4Lie',
    '4dPWFP',
    'ibuyXP',
    'E4b2iU',
    'turkiye',
    'bCLUfX',
    'mRUY1e',
    'x6kgK7',
    'ortak',
    '7qdbf6',
    'zelzele',
]
