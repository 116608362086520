import {
    Menu as MenuIcon,
    Moon as MoonIcon,
    Sun as SunIcon,
    X as XIcon,
    ArrowRight as ArrowRightIcon,
} from 'feather-icons-react'
import splitbee from '@splitbee/web'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { SplitbeeEvents } from '../helper'

export function Sidebar({ onColorSchemeChange }) {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const { t, i18n } = useTranslation()

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        splitbee.track(SplitbeeEvents.changeLanguage, { lng })
    }

    const changeTheme = (theme) => {
        onColorSchemeChange(theme)
        splitbee.track(SplitbeeEvents.themeChange, { theme })
    }

    const go = (url) => {
        navigate(url)
        setOpen(false)
    }

    const sidebarItems = [
        { text: t('readingCircles'), url: '/' },
        { text: t('createCircle'), url: '/create-circle' },
        { text: t('aboutUs'), url: '/about' },
    ]

    return (
        <>
            <button
                className="mr-3 text-gray-500 dark:text-gray-200"
                onClick={() => setOpen(true)}
            >
                <MenuIcon />
            </button>

            <div
                className={`overflow-y-scroll bg-white dark:bg-neutral-900 w-[80%] max-w-md h-screen fixed top-0 transition-all z-50 ${
                    open ? 'left-0' : 'left-[-100%]'
                } shadow-2xl`}
            >
                <div className="flex justify-between items-center mt-3">
                    <button
                        onClick={() => go('/')}
                        className="text-gray-900 dark:text-gray-300 ml-5 flex items-center"
                    >
                        <img
                            src="/logo192.png"
                            alt="logo"
                            className="w-[32px] mr-2"
                        />
                        AppEleven
                    </button>

                    <button
                        className="p-4 pr-5 dark:text-gray-200"
                        onClick={() => setOpen(false)}
                    >
                        <XIcon />
                    </button>
                </div>

                <ul className="p-5 w-full">
                    {sidebarItems.map((item) => (
                        <li key={item.text}>
                            <button
                                className="flex text-left items-center mb-3 text-gray-800 w-full dark:text-gray-300"
                                onClick={() => go(item.url)}
                            >
                                <span className="flex-1">{item.text}</span>{' '}
                                <ArrowRightIcon size={16} />
                            </button>
                        </li>
                    ))}
                    <li className="my-6 border-t dark:border-gray-800" />
                    <li className="font-bold mt-4 text-gray-700 dark:text-gray-300">
                        {t('colorSettings')}
                    </li>
                    <div className="p-1 mt-4 dark:bg-neutral-800 bg-white rounded shadow md:mr-2 mr-1 flex">
                        <button
                            onClick={() => changeTheme('light')}
                            className="flex-1 h-12 flex items-center justify-center text-gray-900 dark:text-gray-200 bg-gray-200 dark:bg-transparent rounded mr-1"
                        >
                            <SunIcon className="mr-2" size={18} />
                            {t('light')}
                        </button>
                        <button
                            onClick={() => changeTheme('dark')}
                            className="flex-1 h-12 flex items-center justify-center text-gray-900 dark:text-gray-200 dark:bg-neutral-900 rounded"
                        >
                            <MoonIcon className="mr-2" size={18} />
                            {t('dark')}
                        </button>
                    </div>

                    <li className="font-bold mt-6 text-gray-700 dark:text-gray-300">
                        {t('languageSettings')}
                    </li>
                    <div className="p-1 mt-4 dark:bg-neutral-800 bg-white rounded shadow md:mr-2 mr-1 flex">
                        <button
                            onClick={() => changeLanguage('tr')}
                            className={cx(
                                'flex-1 h-12 flex items-center justify-center dark:text-gray-200 rounded mr-1',
                                {
                                    ' dark:bg-neutral-900 bg-gray-200':
                                        i18n.language === 'tr',
                                }
                            )}
                        >
                            <span className="text-xl mr-2">🇹🇷</span>
                            Türkçe
                        </button>
                        <button
                            onClick={() => changeLanguage('en')}
                            className={cx(
                                'flex-1 h-12 flex items-center justify-center dark:text-gray-200 rounded',
                                {
                                    ' dark:bg-neutral-900 bg-gray-200':
                                        i18n.language === 'en',
                                }
                            )}
                        >
                            <span className="text-xl mr-2">🇺🇸</span>
                            English
                        </button>
                    </div>

                    <li className="font-bold mt-6 text-gray-700 dark:text-gray-300">
                        {t('downloadApp')}
                    </li>
                    <div className="flex items-center my-2 flex-wrap">
                        <a
                            href="https://apps.apple.com/app/id1602007975"
                            target="_blank"
                            className="mr-3 my-1 flex"
                            data-splitbee-event="App Store Links"
                            data-splitbee-event-destination="AppStore"
                        >
                            <img
                                src={`/assets/img/${
                                    i18n.language === 'tr'
                                        ? 'app-store'
                                        : 'app-store-eng'
                                }.png`}
                                alt="app-store"
                                className="h-12 w-auto"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.hammertech.appeleven"
                            target="_blank"
                            className="my-1 flex"
                            data-splitbee-event="App Store Links"
                            data-splitbee-event-destination="Google Store"
                        >
                            <img
                                src={`/assets/img/${
                                    i18n.language === 'tr'
                                        ? 'google-store'
                                        : 'google-eng'
                                }.png`}
                                alt="app-store"
                                className="h-12 w-auto"
                            />
                        </a>
                    </div>

                    <li className="text-xs mt-6 text-gray-700 dark:text-gray-400 border-t dark:border-gray-800 pt-6">
                        &copy; {new Date().getFullYear()} - {t('copyrightText')}
                    </li>
                </ul>
            </div>
        </>
    )
}
