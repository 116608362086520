import splitbee from '@splitbee/web'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { readCircleApi } from '../api'
import CircleItem from '../components/CircleItem'
import SharedCircles from '../components/SharedCircles'
import { SplitbeeEvents } from '../helper'
import { appLocalStorage, LocalStorageKeys } from '../service/localStorage'

const Home = () => {
    const [items, setItems] = useState({})
    const [tab, setTab] = useState(0)

    const { t } = useTranslation()

    useEffect(() => {
        handleLoadCirclesFromMemo()
    }, [])

    const onTabClick = (_tab) => {
        setTab(_tab)
        splitbee.track(SplitbeeEvents.homeTabChange, {
            tab: _tab === 0 ? 'Public' : 'Own',
        })
    }

    const handleLoadCirclesFromMemo = () => {
        try {
            const circles =
                appLocalStorage.get(LocalStorageKeys.circleHistory) || []
            circles.reverse().map((id) => {
                readCircleApi(id, (data) => {
                    setItems((prevState) => ({
                        ...prevState,
                        [id]: data,
                    }))
                })
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AppEleven | Anasayfa</title>
                <meta name="title" content={'AppEleven | Okuma Halkalarım'} />
                <meta
                    name="og:title"
                    content={'AppEleven | Okuma Halkalarım'}
                />
                <meta
                    name="description"
                    content={
                        'Appeleven uygulaması ile hızlıca ortak dua grupları oluşturabilir ve hedeflerinizi beraberce okuyup takip edebilirsiniz.'
                    }
                />
                <meta
                    name="og:description"
                    content={
                        'Appeleven uygulaması ile hızlıca ortak dua grupları oluşturabilir ve hedeflerinizi beraberce okuyup takip edebilirsiniz.'
                    }
                />
            </Helmet>
            <div className="flex my-4">
                <button
                    onClick={() => onTabClick(0)}
                    className={`flex-1 py-3 rounded-tl-lg border-b-2 ${
                        tab === 0
                            ? 'text-herkul-700 border-herkul-700 dark:text-herkul-500 dark:border-herkul-500'
                            : 'text-gray-700 border-gray-300 dark:text-gray-300 dark:border-gray-600'
                    }`}
                >
                    {t('publicCircles')}
                </button>
                <button
                    onClick={() => onTabClick(1)}
                    className={`flex-1 py-3 rounded-tr-lg border-b-2 ${
                        tab === 1
                            ? 'text-herkul-700 border-herkul-700 dark:text-herkul-500 dark:border-herkul-500'
                            : 'text-gray-700 border-gray-300 dark:text-gray-300 dark:border-gray-600'
                    }`}
                >
                    {t('yourCircles')}
                </button>
            </div>
            {tab === 0 ? (
                <>
                    <h1 className="text-2xl font-bold mb-5 dark:text-gray-300">
                        {t('publicCircles')}
                    </h1>
                    <SharedCircles />
                </>
            ) : (
                <>
                    <h1 className="text-2xl font-bold mb-5 dark:text-gray-300">
                        {t('yourCircles')}
                    </h1>
                    {Object.keys(items)?.length === 0 && (
                        <p className="text-lg text-gray-800 dark:text-gray-200">
                            {t('nullState')}
                            <Link
                                className="text-herkul-700 underline ml-2 dark:text-herkul-500"
                                to="/create-circle"
                            >
                                {t('createCircle')}
                            </Link>
                        </p>
                    )}
                    {Object.keys(items)?.map((key) => {
                        const item = items[key]
                        return <CircleItem key={key} item={item} />
                    })}
                </>
            )}
        </>
    )
}

export default Home
