import React from 'react'
import { useTranslation } from 'react-i18next'

function Donations() {
    const { t } = useTranslation()
    return (
        <div>
            <h1 className="text-xl font-bold mb-5 dark:text-gray-300">
                {t('donationLinks')}
            </h1>

            <a
                href="https://www.embracerelief.org/donation/help-victims-of-earthquake-in-southern-turkey"
                target="_blank"
                rel="noreferrer"
                data-splitbee-event="Donation Link"
                data-splitbee-event-destination="Embrace Relief"
                className="dark:text-white text-gray-900 block p-4 bg-gray-200 rounded mb-4 hover:bg-gray-300 dark:bg-gray-700"
            >
                <span className="block text-xl">🇺🇸 {t('us')}</span>
                <span className="block mt-2">Embrace Relief</span>
            </a>

            <a
                href="https://timetohelp.eu/cause/erdbeben-tuerkei"
                target="_blank"
                rel="noreferrer"
                data-splitbee-event="Donation Link"
                data-splitbee-event-destination="Germany - Timetohelp"
                className="dark:text-white text-gray-900 block p-4 bg-gray-200 rounded mb-4 hover:bg-gray-300 dark:bg-gray-700"
            >
                <span className="block text-xl">🇩🇪 {t('gr')}</span>
                <span className="block mt-2">Timetohelp</span>
            </a>

            <a
                href="https://timetohelp.enthuse.com/cf/turkey-earthquake"
                target="_blank"
                rel="noreferrer"
                data-splitbee-event="Donation Link"
                data-splitbee-event-destination="UK - Timetohelp"
                className="dark:text-white text-gray-900 block p-4 bg-gray-200 rounded mb-4 hover:bg-gray-300 dark:bg-gray-700"
            >
                <span className="block text-xl">🇬🇧 {t('br')}</span>
                <span className="block mt-2">Timetohelp</span>
            </a>

            <a
                href="https://www.timetohelp.nl/noodhulp-turkije"
                target="_blank"
                rel="noreferrer"
                data-splitbee-event="Donation Link"
                data-splitbee-event-destination="Hollanda - Timetohelp"
                className="dark:text-white text-gray-900 block p-4 bg-gray-200 rounded mb-4 hover:bg-gray-300 dark:bg-gray-700"
            >
                <span className="block text-xl">🇳🇱 {t('nl')}</span>
                <span className="block mt-2">Timetohelp</span>
            </a>

            <p className="text-gray-700 dark:text-gray-200">
                {t('donationInfo.firstPart')}{' '}
                <a
                    href="mailto:developer@hamertech.io"
                    className="underline"
                    data-splitbee-event="Email Link | Donations"
                    data-splitbee-event-destination="developer@hamertech.io"
                >
                    developer@hamertech.io
                </a>{' '}
                {t('donationInfo.secondPart')}
            </p>
        </div>
    )
}

export default Donations
