import { Trash } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import { readCircleApi } from '../api'
import CircleItem from './CircleItem'
import Loader from './Loader'

const SharedCircles = () => {
    const [customCircles, setCustomCircles] = useState({})
    const [circleIds, setCircleIds] = useState([])

    useEffect(() => {
        if (circleIds.length) {
            handleLoadCircles()
        }
    }, [circleIds.length])

    const handleLoadCircles = () => {
        try {
            Object.values(circleIds).forEach((id) => {
                readCircleApi(id, (data) => {
                    setCustomCircles((prevState) => ({
                        ...prevState,
                        [id]: data,
                    }))
                })
            })
        } catch (e) {
            console.log(e)
        }
    }

    const onItemClick = (id) => {
        window.parent.postMessage(
            JSON.stringify({
                action: 'VIEW_PAGE',
                id,
            }),
            '*'
        )
    }

    const onDelete = (id) => {
        window.parent.postMessage(
            JSON.stringify({
                action: 'DELETE_CIRCLE',
                id,
            }),
            '*'
        )
    }

    useEffect(() => {
        const { search } = window.location
        const params = new URLSearchParams(search)
        const circles = params.get('circles')?.split(',')
        setCircleIds(circles ?? [])
    }, [])

    if (!Object.entries(customCircles).length) {
        return <Loader />
    }

    return (
        <>
            {Object.entries(customCircles).map(([id, item]) => {
                return (
                    <div className="flex items-center" key={id}>
                        <div className="flex-1">
                            <CircleItem
                                onItemClick={() => onItemClick(id)}
                                key={id}
                                item={item}
                            />
                        </div>

                        <button
                            className="w-12 py-3 flex items-center justify-center text-neutral-600 dark:text-neutral-200 -mt-4"
                            onClick={() => onDelete(id)}
                        >
                            <Trash />
                        </button>
                    </div>
                )
            })}
        </>
    )
}

export default SharedCircles
