import React, { useState } from 'react'
import { auth } from '../service/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import CreateCircle from '../pages/CreateCircle'

const Admin = () => {
    const [email, setEmail] = useState('')
    const [pw, setPw] = useState('')
    const [userId, setUserId] = useState('')

    const onSubmit = async (e) => {
        e.preventDefault()
        signInWithEmailAndPassword(auth, email, pw)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user
                setUserId(user.uid)
                // ...
            })
            .catch((error) => {
                alert('something went wrong')
                console.log({ error })
            })
    }

    return (
        <div>
            {userId === 'GbEcDGHJi4RMdaqiVWQaX5Tf41G2' ? (
                <CreateCircle isAdminView />
            ) : (
                <form onSubmit={onSubmit}>
                    <input
                        className="form-field mb-4"
                        name="title"
                        placeholder="Email"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        type="text"
                        required
                    />
                    <input
                        className="form-field mb-4"
                        placeholder="Password"
                        name="password"
                        onChange={(event) => setPw(event.target.value)}
                        value={pw}
                        type="password"
                        required
                    />
                    <button className="btn">LOGIN</button>
                </form>
            )}
        </div>
    )
}

export default Admin
