import React from 'react'
import { useTranslation } from 'react-i18next'
import SharedCircles from '../components/SharedCircles'
import { PUBLIC_CIRCLE_IDS } from '../service/localStorage'

function Custom() {
    const { t } = useTranslation()
    return (
        <div>
            <h1 className="text-xl font-bold mb-5 dark:text-gray-300">
                {t('linksForEq')}
            </h1>
            <SharedCircles isMobile circleIds={PUBLIC_CIRCLE_IDS} />
        </div>
    )
}

export default Custom
