import {
    ref,
    set,
    increment,
    update,
    onValue,
    orderByChild,
    equalTo,
    query,
    onDisconnect,
    serverTimestamp,
    push,
} from 'firebase/database'
import db from '../service/firebase'

export const createCircleApi = async (circle) => {
    circle.createdAt = serverTimestamp()
    return set(ref(db, 'circles/' + circle.id), circle)
}


export const updateCircleApi = async (circle) => {
    return set(ref(db, 'circles/' + circle.id), circle)
}

export const addCircleToPublic = async (id) => {
    const newCircleId = push(ref(db, 'publicCircles/circleIds'))
    return set(newCircleId, id)
}

// atomic increment
export const incrementCountApi = async (id, count = 1) => {
    const updates = {}
    updates[`circles/${id}/counter`] = increment(count)
    return update(ref(db), updates)
}

export const readCircleApi = (id, callback) => {
    const tasksRef = ref(db, `circles/${id}`)
    onValue(tasksRef, (snapshot) => {
        const data = snapshot.val()
        callback(data)
    })
}


export const readPublicCircleIds = (callback) => {
    const tasksRef = ref(db, `publicCircles/circleIds`)
    onValue(tasksRef, (snapshot) => {
        const data = snapshot.val()
        callback(data)
    })
}
export const readActiveReaderCount = (circleId, callback) => {

    const tempUsers = ref(db, 'tempUser/')
    const queryConstraints = [orderByChild('state'), equalTo(circleId + '-online')]
    onValue(query(tempUsers, ...queryConstraints), (snap) => {
        callback(snap.size)
    })
}

export const trackUser = (userId, circleId) => {
    const userStatusDatabaseRef = ref(db, '/tempUser/' + userId)
    const connnectedRef = ref(db, '.info/connected')


    const isOnlineForDatabase = {
        state: circleId + '-' + 'online',
        last_changed: Date.now(),
    }
    onValue(connnectedRef, (snap) => {
        if (snap.val() === true) {
            onDisconnect(userStatusDatabaseRef)
                .remove()
                .then(() => {
                    set(userStatusDatabaseRef, isOnlineForDatabase)
                })

        }
    })

}
