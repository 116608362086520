import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from '../helper'

const CircleItem = ({ item, onItemClick = null }) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    if (item === null) return null
    const progress = Math.min((100 * item.counter) / item.goal, 100)
    const isFinished = item.counter >= item.goal

    const onRouteChange = () => {
        if (onItemClick) {
            onItemClick(item.id)
        } else {
            navigate(`/${item.id}`)
        }
    }

    return (
        <button
            className="bg-gray-100 dark:bg-neutral-800 block p-4 rounded-lg text-gray-500 mb-4 w-full text-left"
            key={item.id}
            onClick={onRouteChange}
        >
            <span className="flex items-center">
                <span className="flex-1 dark:text-gray-300 font-medium">
                    {i18n.language === 'tr'
                        ? item.title
                        : item.enTitle
                        ? item.enTitle
                        : item.title}
                </span>
                <span className="text-sm dark:text-gray-300">
                    {isFinished ? (
                        item.numOfCompleted > 0 ? (
                            <span className="block text-sm font-bold">
                                {formatNumber(item.numOfCompleted + 1)}.{' '}
                                {t('circle')}
                            </span>
                        ) : (
                            <></>
                        )
                    ) : (
                        <div className="items-end flex flex-col ml-2">
                            <span className="block">
                                <b>
                                    {formatNumber(
                                        item.goal - item.counter,
                                        i18n.language
                                    )}
                                </b>{' '}
                                {t('left')}
                            </span>
                            {item.numOfCompleted > 0 &&
                                (i18n.language === 'tr' ? (
                                    <span className="block text-xs">
                                        <b>
                                            {formatNumber(
                                                item.numOfCompleted,
                                                i18n.language
                                            )}
                                        </b>{' '}
                                        {t('timesCompleted')}
                                    </span>
                                ) : (
                                    <span className="block text-xs">
                                        {t('countInfo.firstPart')}{' '}
                                        <b>
                                            {formatNumber(
                                                item.numOfCompleted,
                                                i18n.language
                                            )}
                                        </b>{' '}
                                        {t('countInfo.secondPart')}
                                    </span>
                                ))}
                        </div>
                    )}
                </span>
            </span>

            <span
                className={`block h-[3px] -mb-4 rounded-full mt-4 relative ${
                    isFinished ? 'dark:bg-herkul-500' : 'bg-herkul-600'
                }`}
                style={{
                    width: `${progress}%`,
                }}
            />
        </button>
    )
}

export default CircleItem
